import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container container--small mb-64" }
const _hoisted_2 = { class: "color-secondary bold" }
const _hoisted_3 = { class: "heading bold mt-16" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('generalAgreement.subTitle')), 1),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('generalAgreement.title')), 1),
    _createElementVNode("div", {
      class: "mt-16",
      innerHTML: _ctx.$t('generalAgreement.text')
    }, null, 8, _hoisted_4)
  ]))
}